.footer {
  $height    : 32px;

  position   : relative;
  background : transparent;


  .toolbar {
    display : none;
  }
}

@media (orientation : portrait) {
  .footer {
    padding : 12px 6px;

    .toolbar {
      display         : flex;
      flex-direction  : row;
      justify-content : space-between;

    }
  }
}

.beyonity-ui--options-header {
    display         : flex;
    width           : 100%;
    justify-content : space-between;
    height          : fit-content;
    align-items     : center;
}

.beyonity-ui--options-header__separator {
    width            : 1px;
    background-color : var(--color-background--gray-300);
    margin           : 0 10px;
    height           : 70%;
}


.beyonity-ui--options-header__bottom-separator {
    padding-bottom : 8px;
    border-bottom  : 1px solid var(--color-background--gray-200);
}
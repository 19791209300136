.beyonity-po--info-flag-collapsible {
    margin-left  : -12px !important;
    margin-right : -12px !important;
    width        : calc(100% + 24px) !important;
}

.beyonity-po--poi-item-card__tooltip {
    width : 150px !important;
}

.beyonity-po--project-item-card__tooltip {
    width : 343px !important;
}

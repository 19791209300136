.controls {
  position        : absolute;
  inset           : 16px;
  pointer-events  : none;
  display         : flex;
  flex-direction  : column;
  justify-content : space-between;


  .top {
    .topTop {
      flex    : 0 0 auto;
      display : none;
      pointer-events : all;
    }

    .topBottom {
      display         : flex;
      justify-content : space-between;
      align-items     : flex-start;
      width           : 100%;

      > div {
        flex      : 0 1 auto;

        > * {
          pointer-events : all;
        }
      }

      .left {
        flex : 1 1 auto;
      }
    }
  }

  .bottom {
    align-items : flex-end;

    .right {
      min-width      : 0;
      pointer-events : all;
      display        : flex;
      flex-direction : column;
      align-items    : flex-end;
    }

    .left {
      min-width      : 0;
      display        : flex;
      flex-grow      : 1;
      flex-direction : column;
      align-items    : flex-start;
    }
  }
}


@media (orientation : portrait) {

  .controls {
    bottom : 100px;
    inset  : 6px;

    .top {
      .topTop {
        margin        : -6px;
        display       : block;
        margin-bottom : 8px;
      }
    }
  }
}


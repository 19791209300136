.loader {
  width           : 100%;
  height          : 100%;
  display         : flex;
  flex-direction  : column;
  justify-content : center;
  align-items     : center;
  font-size       : 13px;
  text-shadow     : 0 0 3px #fff;
}

/* prevent pull-to-refresh for Safari 16+ */
@media screen and (pointer : coarse) {
    @supports (-webkit-backdrop-filter: blur(1px)) and (overscroll-behavior-y: none) {
        html {
            min-height            : 100.3%;
            overscroll-behavior-y : none;
        }
    }
}

/* prevent pull-to-refresh for Safari 9~15 */
@media screen and (pointer : coarse) {
    @supports (-webkit-backdrop-filter: blur(1px)) and (not (overscroll-behavior-y: none)) {
        html {
            height   : 100%;
            overflow : hidden;
        }

        body {
            margin                     : 0px;
            max-height                 : 100%; /* or `height: calc(100% - 16px);` if body has default margin */
            overflow                   : auto;
            -webkit-overflow-scrolling : touch;
        }

        /* in this case to disable pinch-zoom, set `touch-action: pan-x pan-y;` on `body` instead of `html` */
    }
}

.beyonity-po--modal-header__logo {
    z-index                 : 1;
    border-radius           : 0 0 var(--border-radius-R400) var(--border-radius-R400);
    background              : rgba(var(--gray-0), 0.8);
    -webkit-backdrop-filter : blur(2px);
    backdrop-filter         : blur(2px);
    box-shadow              : var(--elevation-E200);
    padding                 : 16px 16px 12px;
    flex                    : none;
    flex-shrink             : 1;
    min-height              : 60px;
    display                 : flex;
    align-items             : center;
}
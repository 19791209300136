/**
    @file text.css
    @version 2.2.0

    This is the main text stylesheet. It allows to switch fonts with minimal configuration
    to be applied throughout the `beyonity-ui` library and applications.

    The `beyonity-ui` library allows for the use of two fonts. Per default
     `Bw Gradual` (secondary) and `Inter` (primary) are used. Where `Bw Gradual` is used for
     headings and large texts while `Inter` is used for body text and small texts.

     Both fonts can easily exchanged in the `:root` of this file by simply changing the names in the
     given variables.

     For example in most of the projects only `Inter` is used. In this case simply set
     `--beyonity--ui-font__primary: "Inter", sans-serif;`
 */


/*----- font import -----*/

@font-face {
    font-family : "Material-Symbols";
    font-style  : normal;
    font-weight : 400;
    src         : url("./fonts/MaterialSymbolsOutlined.woff2") format("opentype");
}

@font-face {
    font-family : "Beyonity";
    src         : url("./fonts/BwGradual-Medium.woff") format("opentype");
    font-style  : normal;
}

@font-face {
    font-family : "Beyonity";
    src         : url("./fonts/BwGradual-ExtraBold.woff") format("opentype");
    font-weight : 800;
    font-style  : normal;
}

@font-face {
    font-family : "Beyonity";
    src         : url("./fonts/BwGradual-Bold.woff") format("opentype");
    font-style  : normal;
    font-weight : 700;
}

@font-face {
    font-family  : "Inter";
    font-style   : normal;
    font-weight  : 400;
    font-display : swap;
    src          : url("./fonts/Inter-Regular.woff2?v=3.11") format("woff2"),
    url("./fonts/Inter-Regular.woff?v=3.11") format("woff");
}

@font-face {
    font-family  : "Inter";
    font-style   : italic;
    font-weight  : 400;
    font-display : swap;
    src          : url("./fonts/Inter-Italic.woff2?v=3.11") format("woff2"),
    url("./fonts/Inter-Italic.woff?v=3.11") format("woff");
}

@font-face {
    font-family  : "Inter";
    font-style   : normal;
    font-weight  : 500;
    font-display : swap;
    src          : url("./fonts/Inter-Medium.woff2?v=3.11") format("woff2"),
    url("./fonts/Inter-Medium.woff?v=3.11") format("woff");
}

@font-face {
    font-family  : "Inter";
    font-style   : italic;
    font-weight  : 500;
    font-display : swap;
    src          : url("./fonts/Inter-MediumItalic.woff2?v=3.11") format("woff2"),
    url("./fonts/Inter-MediumItalic.woff?v=3.11") format("woff");
}

@font-face {
    font-family  : "Inter";
    font-style   : normal;
    font-weight  : 600;
    font-display : swap;
    src          : url("./fonts/Inter-SemiBold.woff2?v=3.11") format("woff2"),
    url("./fonts/Inter-SemiBold.woff?v=3.11") format("woff");
}

@font-face {
    font-family  : "Inter";
    font-style   : italic;
    font-weight  : 600;
    font-display : swap;
    src          : url("./fonts/Inter-SemiBoldItalic.woff2?v=3.11") format("woff2"),
    url("./fonts/Inter-SemiBoldItalic.woff?v=3.11") format("woff");
}

@font-face {
    font-family  : "Inter";
    font-style   : normal;
    font-weight  : 700;
    font-display : swap;
    src          : url("./fonts/Inter-Bold.woff2?v=3.11") format("woff2"),
    url("./fonts/Inter-Bold.woff?v=3.11") format("woff");
}

@font-face {
    font-family  : "Inter";
    font-style   : italic;
    font-weight  : 700;
    font-display : swap;
    src          : url("./fonts/Inter-BoldItalic.woff2?v=3.11") format("woff2"),
    url("./fonts/Inter-BoldItalic.woff?v=3.11") format("woff");
}

@font-face {
    font-family  : "Inter";
    font-style   : normal;
    font-weight  : 800;
    font-display : swap;
    src          : url("./fonts/Inter-ExtraBold.woff2?v=3.11") format("woff2"),
    url("./fonts/Inter-ExtraBold.woff?v=3.11") format("woff");
}

@font-face {
    font-family  : "Inter";
    font-style   : italic;
    font-weight  : 800;
    font-display : swap;
    src          : url("./fonts/Inter-ExtraBoldItalic.woff2?v=3.11") format("woff2"),
    url("./fonts/Inter-ExtraBoldItalic.woff?v=3.11") format("woff");
}

@font-face {
    font-family  : "Inter";
    font-style   : normal;
    font-weight  : 900;
    font-display : swap;
    src          : url("./fonts/Inter-Black.woff2?v=3.11") format("woff2"),
    url("./fonts/Inter-Black.woff?v=3.11") format("woff");
}

@font-face {
    font-family  : "Inter";
    font-style   : italic;
    font-weight  : 900;
    font-display : swap;
    src          : url("./fonts/Inter-BlackItalic.woff2?v=3.11") format("woff2"),
    url("./fonts/Inter-BlackItalic.woff?v=3.11") format("woff");
}

@font-face {
    font-family         : "Inter var";
    font-weight         : 100 900;
    font-display        : swap;
    font-style          : normal;
    font-named-instance : "Regular";
    src                 : url("./fonts/Inter-roman.var.woff2?v=3.11") format("woff2");
}

@font-face {
    font-family         : "Inter var";
    font-weight         : 100 900;
    font-display        : swap;
    font-style          : italic;
    font-named-instance : "Italic";
    src                 : url("./fonts/Inter-italic.var.woff2?v=3.11") format("woff2");
}


:root {
    --beyonity--ui-font__primary   : "Inter", sans-serif;
    /* --beyonity--ui-font__secondary : "Beyonity", sans-serif; */
    --beyonity--ui-font__branding : "Beyonity", sans-serif;
    --beyonity--ui-font__secondary : "Inter", sans-serif;
}


/*----- defined fonts -----*/


/*----- headings / secondary-font use -----*/


h1,
.beyonity-ui--text__display {
    font-family           : var(--beyonity--ui-font__secondary);
    font-style            : normal;
    font-weight           : 800;
    font-size          : 96px;
    line-height        : 96px;
    font-feature-settings : "pnum" on, "lnum" on;
    margin-block-start : 0;
    margin-block-end   : 0;
}

h2,
.beyonity-ui--text__heading-1 {
    font-family        : var(--beyonity--ui-font__secondary);
    font-style         : normal;
    font-weight        : 800;
    font-size          : 32px;
    line-height        : 38px;
    margin-block-start : 0;
    margin-block-end   : 0;
}

h3,
.beyonity-ui--text__heading-2 {
    font-family        : var(--beyonity--ui-font__secondary);
    font-style         : normal;
    font-weight        : 700;
    font-size          : 19px;
    line-height        : 24px;
    margin-block-start : 0;
    margin-block-end   : 0;
}


/*----- large  -----*/

.beyonity-ui--text__large,
.beyonity-ui--text__large-bold,
.beyonity-ui--text__large-highlighted {
    font-family           : var(--beyonity--ui-font__secondary);
    font-style            : normal;
    font-weight           : normal;
    font-size             : 17px;
    line-height           : 24px;
    font-feature-settings : "pnum" on, "lnum" on;
}

.beyonity-ui--text__large {
    font-weight : 500;
}

.beyonity-ui--text__large-bold {
    font-weight : 700;
}

.beyonity-ui--text__large-highlighted {
    font-weight : 800;
}


/*----- medium -----*/

p {
    margin-block-start : 0;
    margin-block-end   : 0;
}

p,
.beyonity-ui--text__medium-light,
.beyonity-ui--text__medium,
.beyonity-ui--text__medium-bold,
.beyonity-ui--text__medium__semi-bold,
.beyonity-ui--text__medium-highlighted {
    font-family           : var(--beyonity--ui-font__secondary);
    font-style            : normal;
    font-weight           : normal;
    font-size             : 15px;
    line-height           : 24px;
    font-feature-settings : "pnum" on, "lnum" on;
}

.beyonity-ui--text__medium-light {
    font-weight : 300;
}

p,
.beyonity-ui--text__medium {
    font-weight : 500;
}

.beyonity-ui--text__medium__semi-bold {
    font-weight : 600;
}

.beyonity-ui--text__medium-bold {
    font-weight : 700;
}

.beyonity-ui--text__medium-highlighted {
    font-weight : 800;
}


/*----- small -----*/

.beyonity-ui--text__small,
.beyonity-ui--text__small-bold,
.beyonity-ui--text__small-extra-bold,
.beyonity-ui--text__small-highlighted {
    font-family : var(--beyonity--ui-font__primary);
    font-style  : normal;
    font-size   : 13px;
    line-height : 18px;
}

.beyonity-ui--text__small {
    font-weight : 500;
}

.beyonity-ui--text__small-highlighted {
    font-weight : 600;
}

.beyonity-ui--text__small-bold {
    font-weight : 700;
}

.beyonity-ui--text__small-extra-bold {
    font-weight : 800;
}

/*----- label -----*/

.beyonity-ui--text__label,
.beyonity-ui--text__label-light {
    font-family           : Inter, sans-serif;
    font-style            : normal;
    font-weight           : 700;
    font-size             : 11px;
    line-height           : 12px;
    letter-spacing        : 0.5px;
    font-feature-settings : "tnum" on, "lnum" on;
}

.beyonity-ui--text__label-light {
    font-weight : 500;
    font-size : 12px;
}

.beyonity-ui--text__mono {
    letter-spacing        : -1px;
    font-feature-settings : "tnum" on, "lnum" on;
}

.beyonity-ui--line-height__small {
    line-height : 1;
}

.beyonity-ui--text__branding {
    font-family : var(--beyonity--ui-font__branding);
}




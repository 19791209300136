.itemCardList {
  display        : flex;
  flex-direction : column;
  gap            : 8px;
  margin           : 8px 8px 8px 16px;

  > * {
    width : 343px;
  }

  .infoSpotList {
    display               : grid;
    grid-template-columns : repeat(2, 1fr);
    gap                   : 8px;

  }
  .infoFlagHeader {
    color : var(--color-text--gray-800);
  }


  .infoSpotWidthWrapper {
    width : 168px;
  }
}


[dir="rtl"] .itemCardList {
  margin-left  : 0;
  margin-right : 16px;
}

@media (orientation : portrait) {

  .itemCardList {
    flex-direction : row;
    margin         : 8px 0;

    > * {
      flex : 1 0 auto;
    }

    &::before,
    &::after {
      content : "";
      flex    : none;
      width   : 8px;
    }

  }

}

.beyonity-ui--app-loading-screen {
    position   : fixed;
    width      : 100%;
    height     : 100%;
    background : var(--color-background--gray-50);
    transition : opacity 1s ease-in-out 3s;
    z-index    : 10000000;
    overflow         : hidden;
    display          : flex;
    align-items      : center;

    --color-gradient : 128, 128, 128;
}

.beyonity-ui--app-loading-screen__gradient {
    position   : absolute;
    width      : calc(100vw + 50px);
    height     : calc(100vh + 50px);
    display    : flex;
    background : radial-gradient(circle, rgba(var(--color-gradient), 0) 60%,
    rgba(var(--color-gradient), .4) 100%)
}


/* sligtly animate the gradient to make it look like a shine */
.beyonity-ui--app-loading-screen__gradient {
    animation : beyonity-ui--app-loading-screen__gradient__animation 10s ease-in-out infinite alternate;
}


@keyframes beyonity-ui--app-loading-screen__gradient__animation {
    0% {
        transform : translateX(0) translateY(10px) scale(1);
    }

    50% {
        transform : translateX(10px) translateY(0px) scale(1.1);
    }

    100% {
        transform : translateX(0px) translateY(10px) scale(1);
    }
}

.beyonity-ui--app-loading-screen__body {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    gap            : 24px;
}

.beyonity-ui--app-loading-screen__content-wrapper {
    display    : flex;
    align-items     : stretch;
    justify-content : space-around;
    height          : fit-content;
    width      : 100%;
    max-height : 200px;
    gap             : 44px;
}

.beyonity-ui--app-loading-screen__content-wrapper > * {
    width   : 50%;
    display : flex;
}

.beyonity-ui--app-loading-screen__customer-logo {
    align-items     : flex-end;
    justify-content : flex-end;
    padding         : 12px;
    opacity         : 0;
}

.beyonity-ui--app-loading-screen__customer-logo img {
    height     : 100%;
    width      : auto;
    transition : opacity .5s ease-in-out;
}

.beyonity-ui--app-loading-screen--customer .beyonity-ui--app-loading-screen__customer-logo {
    opacity    : 1;
    transition : opacity 1s ease-in-out;
}


.beyonity-ui--app-loading-screen__content {
    display        : flex;
    flex-direction : column;
    gap            : 12px;
    max-width      : 350px;
    opacity        : 0;
    align-items     : center;
    justify-content : center;
}

.beyonity-ui--app-loading-screen--with-text .beyonity-ui--app-loading-screen__content {
    opacity    : 1;
    transition : opacity .5s ease-in-out;
}

.beyonity-ui--app-loading-screen__text {
    color      : var(--color-text--gray-800);
    text-align : start;
    /*transition scale small with delay 1s amd duration 1s ease-in-out;*/
    transition : transform 1s ease-in-out 1s;
}

.beyonity-ui--app-loading-screen--finished.beyonity-ui--app-loading-screen {
    opacity        : 0;
    pointer-events : none;
    transition     : opacity 1s ease-in-out 1.5s;
}


.beyonity-ui--app-icon {
    width  : 42px;
    height : 42px;
}

.beyonity-ui--branding {
    display         : flex;
    flex-direction  : row;
    flex            : 0 0 auto;
    align-items     : center;
    justify-content : center;
    gap             : 6px;
}

.beyonity-ui--branding__bey {
    color : rgb(var(--blue-700));
}


.beyonity-ui--app-loading-screen__footer {
    display         : flex;
    gap             : 12px;
    align-items     : center;
    justify-content : flex-end;
}

.beyonity-ui--app-icon.beyonity-ui--animated {
    /*animation: beyonity-ui--app-icon__animation 2s ease-in-out infinite alternate;**/
}

@keyframes beyonity-ui--app-icon__animation {

    0% {
        transform : translateY(0);
    }

    50% {
        transform : translateY(-8px);
    }

    100% {
        transform : translateY(0);
    }
}

@media (max-width : 1200px) {
    .beyonity-ui--app-loading-screen__content-wrapper {
        flex-direction : column;
        gap            : 24px;
        max-height : 100%;
    }

    /* also center the content */
    .beyonity-ui--app-loading-screen__content-wrapper > * {
        width           : 100%;
        justify-content : center;
        text-align      : center !important;
    }

    .beyonity-ui--app-loading-screen__content > * {
        text-align : center;
    }


    .beyonity-ui--app-loading-screen__customer-logo > img {
        width  : 40%;
        height : auto;
    }
}

.beyonity-ui--side-box {
    position       : absolute;
    inset          : 0;
    overflow       : hidden;
    pointer-events : none;
    bottom      : 0;
    will-change : auto;
}

.beyonity-ui--side-box__content {
    position         : absolute;
    left             : -375px;
    width            : 375px;
    max-height       : 100%;
    height           : 100%;
    will-change : auto;
    overflow         : hidden;
    pointer-events   : all;
    display          : flex;
    flex-direction   : column;
    border-radius    : 16px 16px 0 0;
    background-color : var(--color-background--gray-0);
    z-index          : 2;
}


.beyonity-ui--side-box__secondary > .beyonity-ui--side-box__content {
    border-radius : 16px;
    box-shadow    : var(--elevation-E300);
}


@media (orientation : portrait) {

    .beyonity-ui--side-box {
        left : 0;
        margin : 8px 8px 0 8px;
    }

    .beyonity-ui--side-box__content {
        box-shadow : var(--elevation-E300);
        width      : 100%;
        z-index    : 3;
        left       : 0;
        right : 0;
        /*    bottom     : -100%;*/
    }

    .beyonity-ui--side-box.beyonity-ui--side-box__secondary {
        z-index : 12;
    }

}


@media (orientation : landscape) {

    .beyonity-ui--side-box.beyonity-ui--side-box__secondary {
        top    : 64px;
        bottom : 16px;
    }

    .beyonity-ui--open.beyonity-ui--side-box__secondary > .beyonity-ui--side-box__content,
    .beyonity-ui--preview.beyonity-ui--side-box__secondary > .beyonity-ui--side-box__content {
        margin-left : 16px;
        transition  : margin-left 0.1s ease-in;

        [dir="rtl"] & {
            margin-left  : auto;
            margin-right : 16px;
        }
    }

    [dir="rtl"] .beyonity-ui--side-box__content {
        left  : auto;
        right : -375px;
    }
}

.footer {
  $height  : 32px;
  position : relative;
  padding  : 0 16px 16px;

  &::before {
    content        : '';
    display        : block;
    position       : absolute;
    left           : 0;
    width          : 100%;
    height         : $height;
    background     : linear-gradient(to bottom, rgba(var(--gray-0), 0) 0%, rgb(var(--gray-0)) 65%, rgb(var(--gray-0)) 100%);
    transform      : translateY(-1*($height));
    pointer-events : none;
  }

}

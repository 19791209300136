.beyonity-po--marker {
    transition : transform 0.3s ease-in-out;
}

.beyonity-po--marker:hover {
    transform : scale(1.1);
}

.beyonity-po--marker:active {
    transform : scale(0.95);
}

.beyonity-po--active.beyonity-po--marker {
    transform : scale(1.2);
}

.beyonity-po--pin__active {

}

.beyonity-po--marker__background {
    fill       : var(--color-background--ci-tertiary);
    transition : fill 0.3s ease-in-out;
}

.beyonity-po--marker__icon {
    fill : var(--color-text--ci-primary);
}


/*----- active pin -----*/

.beyonity-po--pin__active .beyonity-po--marker__background {
    fill : var(--color-background--ci-secondary);
}

.beyonity-po--pin__active .beyonity-po--marker__icon {
    fill : var(--color-background--ci-tertiary);
}

.beyonity-po--pin__active .beyonity-po--marker {
    transform : scale(1.2);
}


$gap : 4px;

.footer {
  background      : transparent;
  width           : 100%;
  max-width       : 100vw;
  color           : rgb(var(--gray-800));
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  gap             : 8px;
  padding     : 3px 8px;
  line-height : 9px;
  font-size   : 9px;
  font-weight     : 400;

  > div {
    display     : flex;
    align-items : flex-end;
    gap         : $gap;
  }

  .links {

    > *:not(:last-child) {
      border-right  : 1px solid rgb(var(--gray-500));
      padding-right : $gap;
    }

  }

  .version {
    opacity       : 0;
    display       : flex;
    padding-right : $gap;
    border-right  : 1px solid rgb(var(--gray-500));
    transition    : opacity 0.3s;

    &:hover {
      opacity : 1;
    }
  }

  .brand {
    min-width : 0;
    display        : flex;
    flex-direction : row;
    align-items    : center;

    svg {
      display : flex;
      height : 10px;
      width   : auto;
      fill    : rgb(var(--gray-800));

      &:hover {
        fill : rgb(var(--gray-700));
      }
    }

  }

}


@media (orientation : portrait) {

  .footer {
    position : fixed;
    bottom   : 0;
    width    : 100%;
    z-index  : 1;
  }

}

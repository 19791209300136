h4 {
  margin      : 0 0 16px;
  font-size   : 13px;
  font-weight : 600;
  line-height : 18px;
  color       : rgb(var(--gray-500));
}

.form {
  display        : flex;
  flex-direction : column;
  gap           : 24px;
  padding-right : 16px;

  .asp {
    margin-bottom : 16px;
  }

  .contactContentTile {
    display        : flex;
    flex-direction : column;
    gap            : 12px;
  }
}


.footerLabel {
  align-items   : flex-start;
  margin-bottom : 16px;

  > div:first-child {
    top : 2px;
  }

}


.response {
  background-color : rgb(var(--green-400));
  color            : rgb(var(--green-700));
  padding          : 24px;

  &.error {
    background-color : rgb(var(--red-400));
    color            : rgb(var(--red-700));
  }
}

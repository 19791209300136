.beyonity-ui--canvas {
    width    : 100%;
    height   : 100%;
    display : flex;
    position : relative;
    background-color : transparent;
    transition       : background, background-color 1s ease-in-out;
}

.beyonity-ui--canvas__gradient-wrapper.left {
    width    : 50%;
    position : absolute;
    left     : 0;
}

.beyonity-ui--canvas__gradient-wrapper.right {
    width    : 50%;
    position : absolute;
    right    : 0;
}

.beyonity-ui--canvas__gradient-wrapper {
    position : relative;
    width    : 100%;
    height   : 100%;
    flex     : 1;
}

.beyonity-ui--canvas__gradient-wrapper.left:after {
    content    : "";
    display    : block;
    position   : absolute;
    top        : 0;
    left       : 0;
    width      : 100%;
    height     : 100%;
    backdrop-filter : blur(50px) brightness(1.3) saturate(1.2);
    background      : linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.3) 100px, rgba(255, 255, 255, 0.2) 100%);
}

.beyonity-ui--canvas__gradient-wrapper.right:after {
    content         : "";
    display         : block;
    position        : absolute;
    top             : 0;
    left            : 0;
    width           : 100%;
    height          : 100%;
    backdrop-filter : blur(50px) brightness(1.3) saturate(1.2);
    background      : linear-gradient(270deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.3) 100px, rgba(255, 255, 255, 0.2) 100%);
}

.beyonity-ui--canvas__gradient {
    position   : absolute;
    top        : 0;
    left       : 0;
    width      : 100%;
    height     : 100%;
    opacity    : 0;
    z-index    : 0;
    transition : opacity 0.5s ease-in-out;
}

.beyonity-ui--canvas canvas {
    width     : 100%;
    position : absolute;
    height    : 100%;
    animation : fadein 500ms ease-in-out;
}

.beyonity-ui--canvas__plugin-ui {
    inset          : 0;
    width   : 100%;
    height  : 100%;
    padding : 12px;
    position       : absolute;
    pointer-events : none;
}

.beyonity-ui--canvas__plugin-wrapper {
    position       : relative;
    width          : 100%;
    height         : 100%;
    pointer-events : none;
}

.beyonity-ui--canvas-plugin {
    position       : absolute;
    pointer-events : all;
}


@keyframes fadein {
    0% {
        opacity : 0;
    }
    100% {
        opacity : 1;
    }
}

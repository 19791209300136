.beyonity-ui--content-box {
    height         : 100%;
    display        : flex;
    flex-direction : column;
}

.beyonity-ui--content-box__header {
    display     : flex;
    flex-direction : column;
    align-items : center;
    padding        : 12px 20px 16px;
    position    : relative;
    gap            : 2px;
}

.beyonity-ui--content-box__header-top {
    height : 4px;
}

.beyonity-ui--content-box__drag-indicator {
    width         : 40px;
    height        : 4px;
    border-radius : 2px;
    background    : var(--color-background--gray-200);
    margin-bottom : 8px;
    transition    : background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.beyonity-ui--content-box__header:hover .beyonity-ui--content-box__drag-indicator {
    background : var(--color-background--ci-secondary);
}

.beyonity-ui--content-box__header:active .beyonity-ui--content-box__drag-indicator {
    background : var(--color-background--ci-primary);
    /* box-shadow in ci color */
    box-shadow : 0 0 3px 2px var(--color-background--ci-tertiary);
}

.beyonity-ui--content-box__header-bottom {
    flex-direction  : row;
    justify-content : space-between;
    width           : 100%;
    display         : flex;
}

.beyonity-ui--content-box__header-title {
    width      : 100%;
    text-align : center;
}

.beyonity-ui--content-box__header-right-placeholder {
    width : 24px;
}

.beyonity-ui--content-box__header-btn {
    flex       : 0 0 auto;
    text-align : center;
    margin     : 0 20px 0 4px;
    transform  : rotate(90deg);
    transition : transform 350ms ease-in-out;
}

.beyonity-ui--content-box__header-shadow {
    inset          : 0;
    position       : absolute;
    pointer-events : none;
    box-shadow     : var(--elevation-E200);
    z-index : 1;
}


.beyonity-ui--open .beyonity-ui--content-box__header-btn {
    transform : rotate(270deg);
}

.beyonity-ui--preview .beyonity-ui--content-box__header-btn {
    transform : rotate(90deg);
}


@media (orientation : landscape) {
    .beyonity-ui--open .beyonity-ui--content-box__header-btn {
        transform : rotate(360deg);
    }

    .beyonity-ui--preview .beyonity-ui--content-box__header-btn {
        transform : rotate(360deg);
    }
}



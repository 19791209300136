.functions {
  width          : 100%;
  pointer-events : none !important;

  .horizontalScroll {
    padding-bottom : 100vh;
    margin-bottom  : -100vh;

    .function {
      pointer-events : all;
      flex-shrink    : 0;
    }
  }


}


.filterDropdowns {
  display        : flex;
  flex-direction : column;
  width          : 260px;
  gap            : 6px;
  padding        : 16px;

  .filterInfo {
    width           : 100%;
    display         : flex;
    justify-content : space-between;

    .left {
      display        : flex;
      flex-direction : row;
      gap            : 12px;
      align-items    : center;
    }

    .right {

    }

  }
}


.body {
  flex       : 1;
  margin-top : 0;
  position   : relative;

  .toolbar {
    top      : 0;
    z-index  : 1;
    height   : fit-content;
    position : sticky;
    display  : none;
    width    : 100%;
    padding  : 8px 4px 0 8px;
  }
}


@media (orientation : portrait) {
  .body {
    z-index : 1;

    .toolbar {
      display : none;
      padding : 8px 4px 0 16px;
    }
  }
}

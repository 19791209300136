
.unitTypesList,
.properties {

  padding : 16px;

  .headline {
    margin-bottom : 12px;
    color         : var(--color-text--gray-400);
  }

  span {
    text-align : center;
  }

}

.unitTypesList {

  .category {
    font-size     : 13px;
    font-weight   : 600;

    color         : rgb(var(--gray-500));
    line-height   : 18px;
    margin-bottom : 8px;
  }

  .total {
    width           : 100%;
    display         : flex;
    justify-content : space-between;
  }

  .listItem {
    width           : 100%;
    display         : flex;
    justify-content : space-between;

    .typeName {
      display         : flex;
      gap             : 6px;
      align-items     : center;
      justify-content : center;

      > span {
        white-space   : nowrap;
        text-overflow : ellipsis;
        min-width     : 0;
        flex-grow     : 0;
        flex-shrink   : 1;
      }

      .typeColorIndicator {
        width         : 8px;
        height        : 8px;
        border-radius : 50%;
      }
    }

    > span {
      white-space   : nowrap;
      text-overflow : ellipsis;
    }
  }

  .chart {
    width           : 100%;
    display         : flex;
    align-items     : center;
    justify-content : center;
  }
}

.properties {
  .propertyList {
    display               : grid;
    grid-template-columns : repeat(2, 50%);
    grid-row-gap          : 32px;
  }

  .property {
    display         : flex;
    gap             : 4px;
    width           : 100%;
    flex-direction  : column;
    justify-content : flex-start;
  }
}

.usage {
  .usageBody {
    display        : flex;
    flex-direction : column;
    gap            : 12px;


    .navigiagtion {

      > nav {
        box-shadow : var(--elevation-B100);
      }

      li {
        padding : 4px 14px;
      }
    }

    .usageHeader {
      display         : flex;
      flex-direction  : row;
      justify-content : space-between;
      align-items     : center;
      padding-bottom  : 12px;
      border-bottom   : 1px solid var(--color-background--gray-200);
    }

    .categories {
      display        : flex;
      flex-direction : column;
      gap            : 16px;

      .category {
        display        : flex;
        flex-direction : row;
        position       : relative;
        align-items    : center;
        gap            : 12px;


        .dataArea {
          width     : 45%;
          flex      : 0 0 auto;
          max-width : 40%;
          overflow  : hidden;

          .name {
            white-space   : nowrap;
            overflow      : hidden;
            text-overflow : ellipsis;
          }
        }

        .bar {
          width     : 50%;
          flex      : 0 1 auto;
          max-width : 45%;

          .barFull {
            height                     : 54px;
            background                 : linear-gradient(270deg, #DBDBDB 0%, rgba(255, 255, 255, 0.00) 100%);
            border-bottom-right-radius : var(--border-radius-R300);
            border-top-right-radius    : var(--border-radius-R300);
            width                      : 100%;
            transition                 : width var(--annimation-duration--fast) ease-out;


            [dir="rtl"] & {
              border-bottom-right-radius : 0;
              border-top-right-radius    : 0;
              border-bottom-left-radius  : var(--border-radius-R300);
              border-top-left-radius     : var(--border-radius-R300);
              background                 : linear-gradient(90deg, #DBDBDB 0%, rgba(255, 255, 255, 0.00) 100%);
            }


            .barFree {
              height                     : 100%;
              background                 : linear-gradient(270deg, var(--color-background--ci-primary) 0%, rgba(255, 255, 255, 0.00) 100%);
              border-bottom-right-radius : var(--border-radius-R300);
              border-top-right-radius    : var(--border-radius-R300);
              transition                 : width var(--annimation-duration--fast) ease-out;

              [dir="rtl"] & {
                border-bottom-right-radius : 0;
                border-top-right-radius    : 0;
                border-bottom-left-radius  : var(--border-radius-R300);
                border-top-left-radius     : var(--border-radius-R300);
                background                 : linear-gradient(90deg, var(--color-background--ci-primary) 0%, rgba(255, 255, 255, 0.00) 100%);
              }
            }
          }
        }

        .badge {
          position    : absolute;
          top         : 0;
          right       : 0;
          bottom      : 0;
          height      : 100%;
          display     : flex;
          align-items : center;
          margin-left : auto;

          [dir="rtl"] & {
            right : auto;
            left  : 0;
          }
        }


        .badge--usage {
          background : var(--color-background--ci-primary);
          color      : var(--color-text--ci-primary);
        }

        .elevatedBadge {
          box-shadow      : var(--elevation-E200);
          background      : rgba(255, 255, 255, var(--transparency-T200));
          backdrop-filter : blur(var(--blur-T200));
        }
      }
    }

    .header {
      display         : flex;
      width           : 100%;
      justify-content : space-between;
      align-items     : flex-end;
      padding-bottom  : 12px;
      border-bottom   : 1px solid var(--color-background--gray-200);

      .right {
        display        : flex;
        flex-direction : column;
        align-items    : flex-end;
        flex           : 0 0 auto;
      }

      .left {
        display        : flex;
        align-items    : center;
        flex-direction : row;
        gap            : 4px;
      }
    }
  }
}



.scrollbar {
    scrollbar-width : thin;
    scrollbar-color : rgb(var(--gray-200)) rgb(var(--gray-100));
    overflow        : auto;
    touch-action    : pan-x pan-y !important;
}

.scrollbar--light::-webkit-scrollbar-track {
    background : rgb(var(--gray-0));
}

.scrollbar--light::-webkit-scrollbar-thumb {
    background-color : rgb(var(--gray-200));
    border-color     : rgb(var(--gray-0));
}

.scrollbar.scrollbar--light {
    scrollbar-color : rgb(var(--gray-200)) rgb(var(--gray-0));
}


.scrollbar--medium::-webkit-scrollbar-track {
    background : rgb(var(--gray-75));
}

.scrollbar--medium::-webkit-scrollbar-thumb {
    background-color : rgb(var(--gray-200));
    border-color     : rgb(var(--gray-75));
}

.scrollbar.scrollbar--medium {
    scrollbar-color : rgb(var(--gray-200)) rgb(var(--gray-75));
}

.scrollbar--light::-webkit-scrollbar {
    width  : 6px;
    height : 6px;
}

.bvr-ui-sidebar__list .scrollbar--light::-webkit-scrollbar-thumb {
    background-color : rgb(var(--gray-300));
}

.scrollbar--light::-webkit-scrollbar-thumb {
    border-radius    : 8px;
    background-color : rgb(var(--gray-500));
    border           : none;
    border-color     : inherit;
}

.scrollbar--light::-webkit-scrollbar-track {
    background : inherit;
}

* {
    box-sizing : border-box;
}

body, h1, h2, ul, p {
    margin : 0;
    /* prevent swipe to realod on body and html */
    overscroll-behavior : none;
}

html,
body,
#root {
    height : 100%;
}

html {
    font                    : 500 15px/24px "Inter", sans-serif;
    color                   : RGB(var(--gray-1000));
    text-size-adjust        : 100%;
    user-select             : none;
    -webkit-font-smoothing  : antialiased;
    -moz-osx-font-smoothing : grayscale;
}

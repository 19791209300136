.beyonity-ui--canvas-rotate {
    bottom : 12px;
    right  : 12px;
}

.wrapper {
    position : relative;
}


.beyonity-ui--canvas-rotate__rb {
    filter     : brightness(1);
    transition : filter var(--annimation-duration--slow);
}

.beyonity-ui--canvas-rotate__loading-rb {
    filter         : brightness(0.5);
    pointer-events : none;
    transition     : filter var(--annimation-duration--slow);
}

.beyonity-ui--canvas-rotate__loading-progress {
    position : absolute;
    top      : 0;
    left     : 0;
    right    : 0;
    bottom   : 0;
}


.beyonity-ui--canvas-rotate__loading-progress {
    display         : flex;
    justify-content : center;
    align-items     : center;
}
.beyonity-ui--box-area {
    padding : 16px;
    box-shadow : var(--elevation-B100);
    transition : box-shadow ease-in var(--annimation-duration--fast);
}

.beyonity-ui--box-area:hover {
    box-shadow : var(--elevation-E100);
}

.beyonity-ui--box-area__title {
    margin-bottom : 20px;
    color         : var(--color-text--gray-800);
}
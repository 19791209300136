.sidebar {
  width          : 375px;
  max-width      : 100%;
  flex           : 1 0 auto;
  display        : flex;
  position       : relative;
  flex-direction : column;
  z-index        : 1;
  background-color : var(--color-background--gray-75);

  &.hidden {
    margin-left : -375px;
  }

  [dir="rtl"] &.hidden {
    margin-left  : unset;
    margin-right : -375px;
  }
}


@media (orientation : portrait) {
  .sidebar {
    background     : var(--color-background--ci-tertiary);
    position       : relative;
    padding-bottom : 20px;
    box-shadow     : none;
    width          : 100%;

    &.hidden {
      margin-left : 0;
    }

    &[dir="rtl"] .hidden {
      margin-right : 0;
    }

  }

}

.bey-ui-scrollHorizontal {
    -ms-overflow-style : none;
    scrollbar-width    : none;
    overflow-x         : auto;
    overflow-y         : hidden;
    padding-bottom     : 100vh;
    margin-bottom      : -100vh;
}

.bey-ui-scrollHorizontal::-webkit-scrollbar {
    display : none;
}

.bey-ui-scrollHorizontal > div {
    pointer-events : all;
}

.bey-ui-scrollHorizontal > div > * {
    flex-shrink : 0;
}

.bey-ui-scrollHorizontal-Wrapper.scrollable .bey-ui-scrollHorizontal.isDragging > div {
    cursor : grabbing;
}

.bey-ui-scrollHorizontal,
.bey-ui-scrollHorizontal-Wrapper {
    position : relative;
}

.bey-ui-scrollHorizontal-Wrapper.scrollable {
    padding-right : 16px;
    padding-left  : 16px;
}

.bey-ui-scrollHorizontal-Wrapper.scrollable.unScrolled {
    padding-left : 0;
}

.bey-ui-scrollHorizontal-Wrapper.scrollable.unScrolledEnd {
    padding-right : 0;
}

.bey-ui-scrollHorizontal_control {
    position   : absolute;
    top        : 0;
    transition : opacity 250ms ease-in-out;
    z-index    : 1;
}

.bey-ui-scrollHorizontal_prev {
    left : 0;
}

.bey-ui-scrollHorizontal_next {
    right : 0;
}

.bey-ui-scrollHorizontal-Wrapper.unScrolled .bey-ui-scrollHorizontal_prev,
.bey-ui-scrollHorizontal-Wrapper.unScrolledEnd .bey-ui-scrollHorizontal_next {
    opacity        : 0;
    pointer-events : none;
    z-index        : 0;
}

.bey-ui-scrollHorizontal-dragBlock {
    display     : flex;
    align-items : flex-start;
    gap         : 8px;
}

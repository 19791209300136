.body {
  padding : 16px 0 24px 16px;
  width   : 100%;
  flex    : 1;
  display        : flex;
  flex-direction : column;
  height  : 100%;
  gap     : 24px;

  > div {
    width : 343px;
    flex  : 0 0 auto;
  }
}

[dir="rtl"] .body {
  padding : 16px 16px 24px 0;
}

@media (orientation : portrait) {
  .body {
    > div {
      width : calc(100% - 16px);
    }
  }
}

.app {
  height           : 100%;
  background-color : rgb(var(--gray-50));

  .loader {
    height          : 100%;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    align-items     : center;
    font-size       : 13px;
  }

  .appContent {
    height  : 100%;
    display : flex;

    .sidebarFooter {
      padding : 8px 12px 16px;
    }
  }

  .playerHeader {
    display : flex;
    gap     : 8px;


    * {
      flex-grow   : 0;
      flex-shrink : 1;
      min-height  : 0;
    }

    .buttons {
      display        : flex;
      flex-direction : row;
      align-items    : start;
      gap            : 8px;
    }

    .contactButton {
      border         : 2px solid rgb(var(--gray-0));
      padding-top    : 10px !important;
      padding-bottom : 10px !important;
      margin-top     : 0 !important;
    }
  }

  .playerContent {
    height : 100%;
    width  : 100%;
    overflow : hidden;
  }

  .tooltip {

    .clusterTooltip {
      width            : 343px;
      display          : flex;
      flex-direction   : column;
      gap              : 3px;
      padding          : 3px;
      /*background white 50%*/
      background-color : rgba(255, 255, 255, 0.6);
    }
  }

  .toolSide {
    display : flex;
    flex    : 0 1 auto;
    gap     : 6px;
  }

}


@media (orientation : portrait) {

  .app {

    .appContent {
      flex-direction : column-reverse;
    }


  }

}


@media (max-height : 566px) and (orientation : landscape) {

}

@media (max-width : 540px) and (orientation : portrait) {

}

.unitTypesList,
.properties {
  padding : 16px;

  .headline {
    margin-bottom : 12px;
    color         : var(--color-text--gray-400);
  }

  span {
    text-align : center;
  }

}

.unitTypesList {
  .category {
    font-size     : 13px;
    font-weight   : 600;
    color         : rgb(var(--gray-500));
    line-height   : 18px;
    margin-bottom : 8px;
  }

  .total {
    width           : 100%;
    display         : flex;
    justify-content : space-between;
  }

  .listItem {
    width           : 100%;
    display         : flex;
    justify-content : space-between;
  }
}

.properties {
  .propertyList {
    display               : grid;
    grid-template-columns : repeat(2, 50%);
    grid-row-gap          : 32px;
  }

  .property {
    display         : flex;
    width           : 100%;
    flex-direction  : column;
    justify-content : flex-start;
  }
}


.filterBox {
  display        : flex;
  flex-direction : column;
  gap            : 12px;

  .typeFilter,
  .sortFilter {
    display        : flex;
    flex-direction : column;
    gap            : 16px;
  }

  .typeFilters {
    display   : flex;
    flex-wrap : wrap;
    gap       : 8px;

    > * {
      display     : flex;
      flex-shrink : 1 !important;
      flex-grow   : 0 !important;
      flex-basis  : auto !important;
    }

  }


  .orderToggle {
    display     : flex;
    gap         : 12px;
    align-items : center;
  }


  .filterInfo {
    width           : 100%;
    display         : flex;
    justify-content : space-between;

    .left {
      display        : flex;
      flex-direction : row;
      gap            : 12px;
      align-items    : center;
    }

    .right {

    }
  }

}

.reverse {
  flex-direction : column-reverse;
}

.subHeader {

  > *:first-child {
    font-size   : 13px;
    font-weight : 600;
    color       : rgb(var(--gray-700));
    line-height : 20px;
  }

}


